import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import Link from 'next/link';
import nProgress from 'nprogress';
import Router from 'next/router';
import Tooltip from '@mui/material/Tooltip';
import styles from 'pages/podcasts/[podcast-name]/PodcastList.module.scss';
import { supportedLanguages, translate as t } from 'utils/helpers';
import UserContext from 'context/UserContext';
import routes from 'utils/routes';
import Cookies from 'lib/js-cookie';
import { getCreator } from '../../apis/creator-page';

const MyPodcasts = ({
  data,
  loadingStatus,
  lang,
  hideCreate,
  isCreatorPage,
}) => {
  const [userData, setUserData] = useContext(UserContext);

  const confirmPopupForLimit = async (plan) => {
    if (plan === 'PLUS') {
      window?.Froged('open');
    } else {
      Router.push(routes.plans);
    }
  };

  const handleCreatePodcast = async (e) => {
    e?.preventDefault();
    nProgress.start();
    if (userData?.showPlanLimitExceedBanner) {
      setUserData((prevState) => ({
        ...prevState,
        planLimitPopup: {
          showModal: true,
          title: t(lang, 'common')('congratulations'),
          planLimitExceedName: userData?.subscription?.name,
          showSubmitButton: true,
          confirmActionCallback: () => {
            confirmPopupForLimit(userData?.subscription?.name);
          },
          closeActionCallback: () => {},
        },
      }));
    } else {
      const userId = Cookies.get('podcaster');
      if (userId) {
        const response = await getCreator(userId, userData?.token);
        if (response?.status === 200) {
          Router.push(routes.createPodcast);
        } else {
          setUserData((prevState) => ({
            ...prevState,
            showModal: true,
            message: t(lang, 'account')('addDomainToCreatePodcast'),
            submitButtonColor: '#0d122a',
            submitButton: t(lang, 'account')('goToCreatorSettings'),
            showSubmitButton: true,
            confirmActionCallback: () => {
              Router.push(routes.creatorPage);
            },
          }));
        }
      }
    }
    nProgress.done();
  };

  const renderSpinner = () => <Spinner animation="grow" />;
  const redirectToPodcastDetail = (item) => {
    let url;
    if (item?.audio_type === 'donation') {
      url = routes.donationPage(item?.slug);
    } else if (item?.audio_type === 'podcast') {
      url = routes.podcastPage(item?.slug);
    } else {
      url = routes.audioSeriesPage(item?.slug);
    }

    if (isCreatorPage) {
      url = item?.domain_redirect_url;
    }
    return url;
  };
  const renderPodcasts = () => (
    <Row className={styles.singleRow}>
      {data &&
        data?.map((item) => (
          <div
            // xl={2}
            // md={3}
            // sm={6}
            // xs={6}
            className={`${styles.singleCol}`}
            style={{
              opacity:
                (item?.rss_imported_podcast && !item?.rss_import_completed) ||
                (item?.rss_imported_podcast && item?.is_podcast_details_missing)
                  ? 0.8
                  : 1,
            }}
            key={item?.id}>
            <a
              href={redirectToPodcastDetail(item)}
              className={styles.singleGrid}
              data-type={item?.audio_type}
              style={{
                backgroundImage: `url('${item?.cover_webp}')`,
                aspectRatio: '1',
              }}>
              <p className={styles.title}>{item?.title}</p>
            </a>
            {item?.rss_imported_podcast && !item?.rss_import_completed && (
              <div className={styles.loader}>
                <Spinner animation="grow" />
              </div>
            )}
            {item?.rss_imported_podcast &&
              item?.rss_import_completed &&
              item?.is_podcast_details_missing && (
                <Tooltip
                  title={t(
                    lang,
                    'podcast',
                  )('pleaseCompleteThePodcastInformation')}
                  arrow>
                  <div className={styles.podcastInfoMisisng}>
                    <img
                      src="/other-icons/feed-green-slash.svg"
                      alt="incomplete podcast"
                      width="80"
                      height="80"
                    />
                  </div>
                </Tooltip>
              )}
          </div>
        ))}
      {!hideCreate && (
        <div className={`${styles.singleCol} mb-4 ml-3 mr-3`}>
          <Link href={routes.createPodcast}>
            <a
              onClick={handleCreatePodcast}
              className={styles.singleGridCreateNew}
              style={{ aspectRatio: '1' }}>
              <p className={styles.title} style={{ display: 'block' }}>
                {t(lang, 'account')('create')}
              </p>
            </a>
          </Link>
        </div>
      )}
    </Row>
  );

  if (loadingStatus) return renderSpinner();
  return renderPodcasts();
};

MyPodcasts.propTypes = {
  data: PropTypes.array.isRequired,
  loadingStatus: PropTypes.bool.isRequired,
  lang: PropTypes.oneOf(supportedLanguages),
  hideCreate: PropTypes.bool,
};

export default MyPodcasts;
