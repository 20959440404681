import {axios, cancelTokens, getAuthToken} from '.';
import ax from "axios";
import queryString from 'querystring';

const getNewsletterSettings = async (user_id, withToken) => {
  const url = `api/newsletter-settings/${user_id}/`;
  let token = getAuthToken();
  if (withToken) token = { headers: { Authorization: `Token ${withToken}` } };
  return await axios.get(url, { ...token });
};

const createNewsletterSettings = async (payload) => {
  const url = `api/newsletter-settings/`;
  const token = getAuthToken();
  return await axios.post(url, payload, { ...token });
}

const updateNewsletterSettings = async (user_id, payload) => {
  const url = `api/newsletter-settings/${user_id}/`;
  const token = getAuthToken();
  return await axios.patch(url, payload, { ...token });
}

const getCampaignList = async (withToken, params) => {
  cancelTokens?.map((source)=> {
    source.cancel({isCancel: true})
  });
  cancelTokens.length = 0;
  const cancelToken = ax.CancelToken.source();
  cancelTokens.push(cancelToken);
  let url = `api/campaign/`
  if(params){
    url += `?${params}`
  }
  let token = getAuthToken();
  if (withToken) token = { headers: { Authorization: `Token ${withToken}` } };
  return await axios.get(url, { ...token, cancelToken: cancelToken.token });
}

const getCampaignListByCreator = async (userId, count) => {
  const params = {
    user: userId,
    status__in: 'publish,sent',
    page_size: count,
    is_hidden: 'False'
  }
  const url = `api/campaign/?${queryString.stringify(params)}`
  return await axios.get(url);
}

const getCampaignCards = async (withToken) => {
  const url = `api/campaign-dashboard-cards/`
  let token = getAuthToken();
  if (withToken) token = { headers: { Authorization: `Token ${withToken}` } };
  return await axios.get(url, { ...token });
}

const getCampaignTags = async (withToken) => {
  const url = `api/get-tags/`
  let token = getAuthToken();
  if (withToken) token = { headers: { Authorization: `Token ${withToken}` } };
  return await axios.get(url, { ...token });
}

const getCampaignAudience = async (campaign_id, params) => {
  cancelTokens?.map((source)=> {
    source.cancel({isCancel: true})
  });
  cancelTokens.length = 0;
  const cancelToken = ax.CancelToken.source();
  cancelTokens.push(cancelToken);
  let url = `api/get-campaign-audience/${campaign_id}/?${params}`;
  const token = getAuthToken();
  return await axios.get(url, { ...token, cancelToken: cancelToken.token });
}

const getCampaign = async (campaign_id) => {
  const url = `api/campaign/${campaign_id}/`;
  return await axios.get(url);
}

const createCampaign = async (payload) => {
  const url = `api/campaign/`
  const token = getAuthToken();
  return await axios.post(url, payload, { ...token });
}

const updateCampaign = async (campaign_id, payload) => {
  const url = `/api/campaign/${campaign_id}/`;
  const token = getAuthToken();
  const response = await axios.patch(url, payload, { ...token });
  return response;
}

const deleteCampaign = async (campaign_id) => {
  const url = `/api/campaign/${campaign_id}/`;
  const token = getAuthToken();
  return await axios.delete(url, { ...token });
}

const sendCampaignTestEmail = async (payload) => {
  const url = `api/send-campaign-test-email/`;
  const token = getAuthToken();
  return await axios.post(url, payload, { ...token });
}

const unsubscribeEmail = async (payload) => {
  const url = `api/unsubscribe-campaign/`
  return await axios.post(url, payload);
}

const updateEditionViewCount = async (payload) => {
  const url = `api/campaign-visits/`;
  const response = await axios.post(url, payload);
  return response;
};

const getEditorVideoImage = async (payload) => {
  const url = `api/get-editor-video-image/`;
  const token = getAuthToken();
  const response = await axios.post(url, payload, {...token});
  return response;
};

export {
  getNewsletterSettings,
  createNewsletterSettings,
  updateNewsletterSettings,
  getCampaignList,
  getCampaignListByCreator,
  getCampaignCards,
  getCampaignTags,
  getCampaign,
  createCampaign,
  updateCampaign,
  deleteCampaign,
  getCampaignAudience,
  sendCampaignTestEmail,
  unsubscribeEmail,
  updateEditionViewCount,
  getEditorVideoImage
}