import React from 'react';
import Router from 'next/router';
import PropTypes from 'prop-types';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import cookies from 'lib/next-cookies';
import Cookies from 'lib/js-cookie';
import routes from 'utils/routes';
import {
  getSubdomain,
  reservedNames,
  supportedDomains,
  supportedLanguages,
} from '../utils/helpers';
import { getCreatorByName } from '../apis/creator-page';
import CreatorLandingPage from '../components/CreatorLandingPage';

const stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_ID);

const Home = ({
  creatorSubdomain,
  creatorData,
  host,
  isShare,
  token,
  showDonationConfirmation,
  donationObj,
}) => {
  if (creatorSubdomain) {
    return (
      <Elements stripe={stripePromise}>
        <CreatorLandingPage
          creatorSubdomain={creatorSubdomain}
          creatorData={creatorData}
          host={host}
          isShare={isShare}
          token={token}
          showDonationConfirmation={showDonationConfirmation}
          cookieDonationObj={donationObj}
        />
      </Elements>
    );
  }
  return 'hello';
};

Home.getInitialProps = async (context) => {
  const { res, query } = context;
  const { language, token } = cookies(context);
  const slug = query['podcast-name'];
  const host = context?.req?.headers?.host;

  const browserLanguage =
    context?.req?.headers['accept-language']?.split(',')[0] === 'es'
      ? 'es'
      : 'en';

  const redirectLang = language || browserLanguage;
  const subdomain =
    context?.req?.headers?.host
      ?.split('.')
      .slice(0, context?.req?.headers?.host.includes('localhost') ? -1 : -2) ||
    getSubdomain();

  if (subdomain?.length > 0 && !reservedNames.includes(subdomain[0])) {
    if (subdomain.length > 0 && !subdomain[0]?.includes(supportedDomains)) {
      if (!supportedLanguages.includes(slug)) {
        const response = await getCreatorByName(subdomain[0], token);
        let showDonationConfirmation = false;
        if (response?.status === 200 && response?.data?.publish_creator_page) {
          const userSubscribed = response?.data?.donation_products?.find(
            (product) => product.type === 0,
          ).user_subscribed;
          if (userSubscribed) {
            if (
              process.env.NEXT_PUBLIC_FRONTEND_URL === 'http://localhost:3000'
            ) {
              Cookies.remove('showDonationConfirmation', { path: '/' });
            } else {
              Cookies.remove('showDonationConfirmation', {
                path: '/',
                domain: '.mumbler.io',
              });
            }
            showDonationConfirmation = false;
          } else {
            showDonationConfirmation = Boolean(
              cookies(context).showDonationConfirmation,
            );
          }
          return {
            creatorSubdomain: subdomain[0],
            creatorData: response?.data,
            host,
            isShare: query?.share,
            token,
            showDonationConfirmation,
            donationObj: cookies(context).donationObj,
          };
        }
        if (res) {
          res.writeHead(301, {
            Location: routes.error404,
            'Cache-Control': 'no-cache',
          });
          res.end();
        } else {
          await Router.push(routes.error404);
        }
        return {};
      }
    }
  } else if (res) {
    res.writeHead(301, {
      Location: routes.home(redirectLang),
    });
    res.end();
  } else {
    await Router.push(routes.home(redirectLang));
  }
  return {};
};

Home.propTypes = {
  creatorSubdomain: PropTypes.string,
  creatorData: PropTypes.object,
  host: PropTypes.string,
  isShare: PropTypes.bool,
  token: PropTypes.string,
  showDonationConfirmation: PropTypes.bool,
  donationObj: PropTypes.object,
};

export default Home;
