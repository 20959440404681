import { axios, getAuthToken } from '.';

const getCreator = async (user_id, withToken) => {
  const url = `api/creator-page/${user_id}/`;
  let token = getAuthToken();
  if (withToken) token = { headers: { Authorization: `Token ${withToken}` } };
  const response = await axios.get(url, { ...token });
  return response;
};

// for creator landing page
const getCreatorByName = async (creator_subdomain, withToken, queryParams) => {
  let url =  `/api/creator-page/${creator_subdomain}/`;
  if(queryParams){
    url = `/api/creator-page/${creator_subdomain}/?${queryParams}`;
  }
  let token = null;
  if (withToken) token = { headers: { Authorization: `Token ${withToken}` } };
  return await axios.get(url, {...token});
};

const createCreator = async (payload) => {
  const url = `api/creator-page/`;
  const token = getAuthToken();
  const response = await axios.post(url, payload, { ...token });
  return response;
};

const updateCreator = async (payload, user_id) => {
  const url = `api/creator-page/${user_id}/`;
  const token = getAuthToken();
  const response = await axios.patch(url, payload, { ...token });
  return response;
};

const subscribeCreator = async (payload) => {
  const url = `api/creator-page-subscribe/`;
  return await axios.post(url, payload);
};

const checkCreator = async (creator_name) => {
  const url = `api/check-creatorname/?creator_name=${creator_name}`;
  const token = getAuthToken();
  return await axios.get(url, { ...token });
};

export {
  getCreator,
  createCreator,
  updateCreator,
  getCreatorByName,
  subscribeCreator,
  checkCreator,
};
